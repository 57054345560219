import {
  Box,
  Button,
  Flex,
  FormControl,
  FormHelperText,
  FormLabel,
  Icon,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  useToast,
} from "@chakra-ui/react";
import "@uppy/core/dist/style.css";
import "@uppy/drag-drop/dist/style.css";
import "@uppy/progress-bar/dist/style.css";
import { DragDrop } from "@uppy/react";
import { debounce } from "lodash";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { FaFileAlt } from "react-icons/fa";
import {
  ContractDto,
  SignedDocumentDto,
  SignerDto,
  TextDocumentDto,
  useCreateContractTextDocumentMutation,
  useCreateSignedDocumentMutation,
  useDeleteTextDocumentMutation,
  useListTextDocumentsForContractQuery,
  useUpdateDocumentNameMutation,
  useUpdateTextDocumentMutation,
} from "../../../../autogen/bff-api";
import { TextDocumentModal } from "../../../../common/documents/TextDocumentModal";
import { useUppyUploader } from "../../../../common/documents/UppyUploader/useUppyUploader";
import { isFetchBaseQueryError } from "../../../../common/errors/isFetchBaseQueryError";
import { useApiError } from "../../../../common/errors/useApiError";
import { SelectorValue } from "../../../../common/input/Selector/SelectorValue";
import { generateHTMLFromTextDocument } from "../../../../common/input/TipTap/downloadTextDocument";
import { TextDocumentTable } from "../../../../common/input/TipTap/TextDocumentTable";
import { useAppDispatch } from "../../../../common/redux/hooks";
import { loadContractThunk } from "../../../../common/redux/thunks/contract/load-contract-thunk";
import { requireDocumentFields, requireEditingMetaFields } from "../../typeguards";
import { useContractState } from "../useContractState";
import "./Docs.scss";
import { FileRow } from "./FileRow";
import { DocumentSignersModal } from "./signatures/DocumentSignersModal";
import { SendForSigningModal } from "./signatures/SendForSigningModal";
import { SignedDocumentRow } from "./signatures/SignedDocumentRow";
interface Props {
  previousStep: () => void;
  nextStep: () => void;
}

export const Docs = ({ previousStep, nextStep }: Props) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const contractState = useContractState();
  const documentFields = requireDocumentFields(contractState.contract as ContractDto);
  const editingMetaFields = requireEditingMetaFields(contractState.contract as ContractDto);
  const [createSignedDocument] = useCreateSignedDocumentMutation();
  const [updateDocumentName] = useUpdateDocumentNameMutation();
  const [createTextDocument, { isLoading: isCreatingTextDocument }] = useCreateContractTextDocumentMutation();
  const [updateTextDocument, { isLoading: isUpdatingTextDocument }] = useUpdateTextDocumentMutation();
  const [deleteTextDocument] = useDeleteTextDocumentMutation();
  const { data: textDocumentRes, isLoading: isLoadingTextDocuments } = useListTextDocumentsForContractQuery({
    contractId: contractState.id,
  });
  const toast = useToast();
  const displayer = useApiError();

  const loadContract = useCallback(() => {
    dispatch(loadContractThunk({ contractId: contractState.id }));
  }, [contractState.id, dispatch]);

  const { uppy, allFiles, documents, setDocuments, setUploads, removeUpload } = useUppyUploader({
    entityId: contractState.id,
    entityType: "Contract",
    reloadEntity: loadContract,
  });

  const hasSignatureAccess = useMemo(() => {
    return contractState.contract.owningOrgHasSignatureAccess && contractState.contract.canEditEverything;
  }, [contractState.contract.canEditEverything, contractState.contract.owningOrgHasSignatureAccess]);

  const signableDocuments = useMemo(() => {
    return documents.filter((d) => d.fileExtension === ".pdf" && d.size < 25_000_000);
  }, [documents]);

  const [textDocumentToEdit, setTextDocumentToEdit] = useState<TextDocumentDto>();
  const [documentToSign, setDocumentToSign] = useState<string>();
  const [signedDocumentToShow, setSignedDocumentToShow] = useState<SignedDocumentDto>();
  const [signedDocuments, setSignedDocuments] = useState<SignedDocumentDto[]>([]);

  useEffect(() => {
    setSignedDocuments(documentFields.signedDocuments);
  }, [documentFields.signedDocuments]);

  useEffect(() => {
    setUploads(editingMetaFields.uploads);
  }, [editingMetaFields.uploads, setUploads]);

  useEffect(() => {
    setDocuments(documentFields.documents);
  }, [documentFields.documents, setDocuments]);

  const sendForSigning = useCallback(
    async (values: {
      mainDocumentId: string;
      attachments?: SelectorValue[];
      signers?: SignerDto[];
      deadline?: string;
    }) => {
      if (!values.signers) throw new Error("Signers are not defined");

      const mainDocumentId = signableDocuments.find((s) => s.id === values.mainDocumentId)?.id;
      const textDocument = textDocumentRes?.documents.find((d) => d.id === values.mainDocumentId);
      const documentAttachments = signableDocuments?.filter((s) =>
        values.attachments?.map((a) => a.value).includes(s.id)
      );
      const textDocumentAttachments = textDocumentRes?.documents?.filter((d) =>
        values.attachments?.map((a) => a.value).includes(d.id)
      );
      try {
        const res = await createSignedDocument({
          contractId: contractState.id,
          createSignedDocumentRequest: {
            mainDocumentId,
            textDocumentInput: textDocument
              ? {
                  textDocumentId: textDocument.id,
                  htmlContent: generateHTMLFromTextDocument(textDocument.text),
                }
              : undefined,
            attachments: documentAttachments?.map((a) => a.id),
            textDocumentAttachments: textDocumentAttachments?.map((d) => ({
              textDocumentId: d.id,
              htmlContent: generateHTMLFromTextDocument(d.text),
            })),
            signers: values.signers,
            language: "No",
            deadline: values.deadline,
          },
        }).unwrap();
        if (res.documentFields) setSignedDocuments(res.documentFields?.signedDocuments);
        toast({ title: t("Contract sent for signing"), status: "success" });
        setDocumentToSign(undefined);
      } catch (e) {
        if (isFetchBaseQueryError(e)) displayer.trigger(e);
        displayer.trigger(new Error(t("Something went wrong") ?? ""));
      }
    },
    [contractState.id, createSignedDocument, displayer, signableDocuments, t, textDocumentRes?.documents, toast]
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedOnUpdateName = useCallback(
    debounce(({ docId, name }: { docId: string; name: string }) => {
      updateDocumentName({
        docId,
        updateDocumentNameRequest: { name },
      });
    }, 300),
    []
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedUpdateTextDocument = useCallback(
    debounce(
      ({
        textDocumentId,
        name,
        text,
        tags,
      }: {
        textDocumentId: string;
        name?: string;
        text?: string;
        tags?: string[];
      }) => {
        updateTextDocument({
          updateTextDocumentRequest: {
            id: textDocumentId,
            name,
            text,
            tags,
          },
        });
      },
      300
    ),
    []
  );

  return (
    <>
      {signedDocumentToShow && (
        <DocumentSignersModal
          contractId={contractState.id}
          signedDocument={signedDocumentToShow}
          onClose={() => setSignedDocumentToShow(undefined)}
          onCancelled={() => {
            setSignedDocuments((docs) => {
              const updatedDocs = [...docs];
              const index = docs.findIndex((d) => d.id === signedDocumentToShow.id);
              if (index !== -1) updatedDocs[index] = { ...signedDocumentToShow, status: "Canceled" };
              return updatedDocs;
            });
          }}
        />
      )}
      {textDocumentToEdit && (
        <TextDocumentModal
          textDocument={textDocumentToEdit}
          onClose={() => setTextDocumentToEdit(undefined)}
          onUpdate={({ title, content, tags }: { title?: string; content?: string; tags?: string[] }) => {
            if (!textDocumentToEdit) throw Error("No text document id - could not edit");
            debouncedUpdateTextDocument({
              textDocumentId: textDocumentToEdit.id,
              name: title,
              text: content,
              tags,
            });
          }}
          isUpdating={isUpdatingTextDocument}
        />
      )}
      {documentToSign && (
        <SendForSigningModal
          contractId={contractState.id}
          mainDocumentId={documentToSign}
          textDocuments={textDocumentRes?.documents}
          documents={signableDocuments}
          sendForSigning={sendForSigning}
          onClose={() => setDocumentToSign(undefined)}
        />
      )}
      <FormControl pt={8} pb="5">
        <FormLabel>{t("Upload documents")}</FormLabel>
        <Box id="dnd">
          <DragDrop uppy={uppy} style={{ backgroundColor: "transparent" }} />
        </Box>
        <FormHelperText>{t("Upload your contract documents")}</FormHelperText>
      </FormControl>
      <Box borderRadius={10} width="full" border="1px solid" borderColor={"smBorder"} pb="2">
        <Table variant="simple" size="sm">
          <Thead>
            <Tr>
              <Th>{t("Name")}</Th>
              <Th colSpan={5}></Th>
            </Tr>
          </Thead>
          <Tbody>
            {allFiles.map((d) => (
              <FileRow
                key={d.uploadId}
                file={d}
                name={d.name}
                remove={removeUpload}
                setDocumentToSign={hasSignatureAccess ? setDocumentToSign : undefined}
                onUpdateName={
                  d.status === "UploadCompleted"
                    ? async (name: string) => {
                        debouncedOnUpdateName({ docId: d.documentId, name });
                        setDocuments((docs) => {
                          const i = docs.findIndex((doc) => doc.id === d.documentId);
                          const updatedDoc = { ...docs[i], name };
                          const updatedDocs = [...docs];
                          updatedDocs[i] = updatedDoc;
                          return updatedDocs;
                        });
                      }
                    : undefined
                }
              />
            ))}
            {signedDocuments.map((d) => (
              <SignedDocumentRow
                name={d.name}
                key={d.id}
                document={d}
                setSignedDocumentToShow={setSignedDocumentToShow}
              />
            ))}
            {!allFiles?.length && !signedDocuments.length && (
              <Tr>
                <Td colSpan={5} width="full" textAlign="center" fontSize="small">
                  <Box p="4">{t("No documents")}</Box>
                </Td>
              </Tr>
            )}
          </Tbody>
        </Table>
      </Box>
      <FormControl pt={4}>
        <FormLabel>{t("Create text document")}</FormLabel>
        <Flex flexDirection="column" alignItems="start">
          <Button
            colorScheme="teal"
            rightIcon={<Icon as={FaFileAlt} />}
            isLoading={isCreatingTextDocument}
            onClick={async () => {
              const res = await createTextDocument({
                createContractTextDocumentRequest: {
                  name: "",
                  text: "",
                  contractId: contractState.id,
                  tags: [],
                },
              });
              if ("error" in res) displayer.show(res.error);
              else setTextDocumentToEdit(res.data);
            }}
          >
            {t("Create")}
          </Button>
          <FormHelperText>{t("Create a new document from scratch or based on templates")}.</FormHelperText>
        </Flex>
      </FormControl>
      <FormControl pt={8}>
        <FormLabel>{t("Text documents")}</FormLabel>
        <TextDocumentTable
          isLoading={isLoadingTextDocuments}
          documents={textDocumentRes?.documents}
          onClick={setTextDocumentToEdit}
          onDelete={async (id: string) => {
            await deleteTextDocument({ id });
          }}
          setDocumentToSign={hasSignatureAccess ? setDocumentToSign : undefined}
        />
      </FormControl>
      <Flex pt="8" pb="10">
        <Button variant={"outline"} mr="5px" w="100%" colorScheme={"teal"} onClick={previousStep}>
          {t("Previous")}
        </Button>
        <Button variant={"solid"} w="100%" colorScheme={"teal"} onClick={nextStep}>
          {t("Next")}
        </Button>
      </Flex>
    </>
  );
};
