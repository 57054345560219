import { Flex, Icon, IconButton, Tag, Td, Text, Tr, useToast } from "@chakra-ui/react";
import { t } from "i18next";
import { useState } from "react";
import { FaFileDownload, FaSignature, FaTrash } from "react-icons/fa";
import { TextDocumentDto } from "../../../autogen/bff-api";
import { Explanation } from "../../support/Explanation/Explanation";
import { downloadTextDocument } from "./downloadTextDocument";

export const TextDocumentTableRow = ({
  doc,
  onClick,
  isDownloadable,
  onDelete,
  setDocumentToSign,
}: {
  doc: TextDocumentDto;
  onClick: (doc: TextDocumentDto) => void;
  isDownloadable?: boolean;
  onDelete?: (id: string) => void | Promise<void>;
  setDocumentToSign?: (id: string) => Promise<void> | void;
}) => {
  const toast = useToast();

  const [isDeleting, setIsDeleting] = useState(false);

  return (
    <Tr
      key={doc.id}
      justifyContent="space-between"
      rounded="md"
      _hover={{ cursor: "pointer", backgroundColor: "smBackgroundSecondary" }}
      p="4"
      my="1"
      onClick={() => onClick(doc)}
    >
      <Td>
        <Flex alignItems="center">
          <Flex
            _dark={{ backgroundColor: "teal.200", color: "gray.800" }}
            backgroundColor="smPrimary"
            color="smBackground"
            rounded="full"
            width="6"
            height="6"
            fontWeight="bold"
            fontSize="sm"
            fontFamily="serif"
            justifyContent="center"
            alignItems="center"
            mr="6"
          >
            {doc.name ? doc.name.substring(0, 1) : "T"}
          </Flex>
          <Text fontSize="sm">{doc.name ? doc.name : `${t("No title")}...`}</Text>
        </Flex>
      </Td>
      <Td fontSize="sm">
        {new Date(doc.modifiedAt).toLocaleString()}
        {doc.modifiedBy ? ` ${t("by")} ${doc.modifiedBy?.firstName} ${doc.modifiedBy?.lastName}` : ""}
      </Td>
      <Td>
        <Flex flexWrap="wrap" columnGap="1" rowGap="1" maxWidth="60">
          {doc.tags.map((tag) => (
            <Tag key={tag} fontSize="sm">
              {tag}
            </Tag>
          ))}
        </Flex>
      </Td>
      <Td textAlign="end">
        <Flex columnGap="1">
          {isDownloadable && (
            <IconButton
              aria-label="download text document"
              size="sm"
              icon={<Icon as={FaFileDownload} />}
              onClick={async (e) => {
                e.stopPropagation();
                if (!doc.text) {
                  toast({ variant: "subtle", status: "warning", title: t("Cannot download empty document") });
                  return;
                }
                try {
                  await downloadTextDocument({ fileName: doc.name, content: doc.text });
                } catch (e) {
                  toast({ variant: "subtle", status: "error", title: t("Failed to download document") });
                }
              }}
            />
          )}
          {setDocumentToSign && (
            <Explanation text={t("Sign document")}>
              <IconButton
                aria-label="sign document"
                size="sm"
                icon={<Icon as={FaSignature} />}
                onClick={(e) => {
                  e.stopPropagation();
                  setDocumentToSign(doc.id);
                }}
              />
            </Explanation>
          )}
          {onDelete && (
            <IconButton
              aria-label="delete text document"
              size="sm"
              icon={<Icon as={FaTrash} />}
              isLoading={isDeleting}
              onClick={async (e) => {
                e.stopPropagation();
                try {
                  setIsDeleting(true);
                  await onDelete(doc.id);
                } catch (e) {
                  toast({ variant: "subtle", status: "error", title: t("Failed to delete document") });
                }
                setIsDeleting(false);
              }}
            />
          )}
        </Flex>
      </Td>
    </Tr>
  );
};
