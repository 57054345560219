import { Flex, Icon, IconButton, Input, Td, Text, Tr } from "@chakra-ui/react";
import { t } from "i18next";
import { FaFileDownload, FaSignature } from "react-icons/fa";
import { PersonDto } from "../../../../autogen/bff-api";
import { DownloadDocumentIconButton } from "../../../../common/documents/DownloadDocumentIconButton/DownloadDocumentIconButton";
import { ProgressBar } from "../../../../common/documents/UppyUploader/ProgressBar";
import { RemoveFileButton } from "../../../../common/documents/UppyUploader/RemoveFileButton";
import { displayDate } from "../../../../common/formatting/displayDate";
import { Explanation } from "../../../../common/support/Explanation/Explanation";
import { ProfileImageRounded } from "../../../sourcing-events/buyer/view-all/ProfileImageRounded";

interface Props {
  file: FileUpload;
  name: string;
  remove: (file: FileUpload) => Promise<void>;
  onUpdateName?: (name: string) => Promise<void>;
  setDocumentToSign?: (id: string) => Promise<void> | void;
}

export type FileUpload =
  | {
      status: "UploadingToBrowser";
      name: string;
      uploadId: string;
    }
  | {
      status: "UploadingToServer";
      name: string;
      uploadId: string;
    }
  | {
      status: "UploadFailed";
      name: string;
      uploadId: string;
    }
  | UploadedFile;

type UploadedFile = {
  status: "UploadCompleted";
  name: string;
  documentId: string;
  uploadId: string;
  uploadedBy: PersonDto;
  uploadedAt: string;
  fileExtension: string;
  size: number;
};

export const isSignable = (file: { size: number; fileExtension: string }) =>
  file.fileExtension === ".pdf" && file.size < 25_000_000;

export const FileRow = ({ file, name, remove, onUpdateName, setDocumentToSign }: Props) => {
  if (file.status !== "UploadCompleted")
    return (
      <>
        <Tr>
          <Td borderBottom="none" pb="2">
            <Text fontSize={"sm"}>{name}</Text>
          </Td>
          <Td colSpan={setDocumentToSign ? 3 : 2} borderBottom="none" pb="2"></Td>
          <Td borderBottom="none" pb="2">
            <IconButton aria-label="disabled download icon" icon={<Icon as={FaFileDownload} />} isDisabled />
          </Td>
          <Td borderBottom="none" pb="2">
            <RemoveFileButton
              confirmRemoval={async () => {
                await remove(file);
              }}
            />
          </Td>
        </Tr>
        <Tr>
          <Td borderTop="none" colSpan={setDocumentToSign ? 6 : 5} pt="0">
            <ProgressBar status={file.status} />
          </Td>
        </Tr>
      </>
    );

  return (
    <Tr>
      <Td>
        <Input
          size="sm"
          rounded="md"
          fontWeight={"normal"}
          value={name}
          _focus={{
            borderColor: onUpdateName ? "default" : "inherit",
            boxShadow: onUpdateName ? "default" : "inherit",
          }}
          _disabled={{ color: "smMuted", border: "none" }}
          isDisabled={file.status !== "UploadCompleted" || !onUpdateName}
          onChange={(e) => onUpdateName?.(e.target.value)}
        />
      </Td>
      <Td>
        {file.status === "UploadCompleted" && (
          <Flex alignItems="center">
            <Flex>
              <ProfileImageRounded
                name={file.uploadedBy.firstName}
                backgroundColor={"smPrimary"}
                width={"5"}
                fontSize="xx-small"
              />
            </Flex>
            <Flex pl="1">
              <Text fontSize={"sm"}>{`${file.uploadedBy.firstName} ${file.uploadedBy.lastName}`}</Text>
            </Flex>
          </Flex>
        )}
      </Td>
      <Td>{file.status === "UploadCompleted" && <Text fontSize={"sm"}>{displayDate(file.uploadedAt)}</Text>}</Td>
      <Td>{file.status === "UploadCompleted" && <DownloadDocumentIconButton documentId={file.documentId} />}</Td>
      {setDocumentToSign && (
        <Td>
          {
            <Explanation
              text={
                isSignable(file)
                  ? t("Sign document")
                  : t("A document must be a valid pdf with less than 25 MB to be signed")
              }
            >
              <IconButton
                aria-label="sign document"
                size="sm"
                isDisabled={!isSignable(file)}
                icon={<Icon as={FaSignature} />}
                onClick={() => setDocumentToSign(file.documentId)}
              />
            </Explanation>
          }
        </Td>
      )}
      <Td>
        <RemoveFileButton
          confirmRemoval={async () => {
            await remove(file);
          }}
        />
      </Td>
    </Tr>
  );
};
