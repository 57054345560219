import { Button, Flex, GridItem, Heading, Icon, Spacer, Text } from "@chakra-ui/react";
import moment from "moment";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { FaClock, FaComments, FaCopy, FaEdit } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import {
  BseDto,
  useCreateSourcingEventCopyMutation,
  useUpdateBasicSourcingEventMutation,
} from "../../../../autogen/bff-api";
import { ConversationModal } from "../../../../common/ConversationModal";
import { useApiError } from "../../../../common/errors/useApiError";
import { displayDate } from "../../../../common/formatting/displayDate";
import { displayDateWithTime } from "../../../../common/formatting/displayDateWithTime";
import { useAppDispatch } from "../../../../common/redux/hooks";
import { setSourcingEvent } from "../../../../common/redux/reducers/basicSourcingEventReducer";
import { useSub } from "../../../../common/subscription/useSub";
import { Explanation } from "../../../../common/support/Explanation/Explanation";
import { urls } from "../../../../urls";
import { requirePublishedOrAwardedState } from "../../requirePublishedOrAwardedState";
import { isPublishedBse } from "../../typeguards";
import { Intro } from "./Intro";

const requireDescription = (bse: BseDto): string => {
  const fields = requirePublishedOrAwardedState(bse);
  return fields.description;
};

interface Props {
  data: BseDto;
}

export const EventMeta = ({ data }: Props) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [editEvent, { isLoading: isLoadingEditEvent }] = useUpdateBasicSourcingEventMutation();
  const [createEventCopy, { isLoading: isLoadingEventCopy }] = useCreateSourcingEventCopyMutation();
  const sub = useSub();
  const displayer = useApiError();

  const [isConversationModalOpen, setIsConversationModalOpen] = useState(false);

  const deadline = data.awardedFields?.deadline || data.publishedFields?.deadline;
  const description = requireDescription(data);

  return (
    <>
      <GridItem area={"title"} display="flex" flexDir={"column"} pt="2.5" pl="4">
        <Heading as="h2" size="md">
          {data.title}
        </Heading>
        <Spacer />
      </GridItem>
      <GridItem
        area="edit"
        display={"flex"}
        flexDirection={"column"}
        alignItems="end"
        justifyContent={"space-between"}
        height="full"
        pt="2.5"
        pr="6"
        pb="5"
      >
        <Flex flexDirection={"column"}>
          {isPublishedBse(data) && (
            <Explanation enabled={!data.canEdit} text={t("Only admins and creators can edit events") ?? ""}>
              <Button
                isDisabled={!data.canEdit}
                leftIcon={<Icon as={FaEdit} />}
                variant={"outline"}
                colorScheme="teal"
                size={"sm"}
                mb="2"
                onClick={() => navigate(urls.events.edit.go(data.id).intro)}
              >
                {t("Edit event")}
              </Button>
            </Explanation>
          )}
          <Flex justifyContent={"end"}>
            <Explanation enabled={!data.canEdit} text={t("Only admins and creators can copy events") ?? ""}>
              <Button
                isDisabled={!data.canEdit}
                leftIcon={<Icon as={FaCopy} />}
                variant={"outline"}
                colorScheme="teal"
                size={"sm"}
                mb="2"
                isLoading={isLoadingEventCopy}
                onClick={async () => {
                  const res = await createEventCopy({ eventId: data.id });
                  if ("data" in res) navigate(urls.events.edit.go(res.data.id).intro);
                  else displayer.trigger(res.error);
                }}
              >
                {t("Copy event")}
              </Button>
            </Explanation>
          </Flex>
        </Flex>
        <Button
          leftIcon={<Icon as={FaComments} />}
          variant={"outline"}
          colorScheme="cyan"
          size={"sm"}
          onClick={() => setIsConversationModalOpen(true)}
        >
          {t("Internal conversation")}
        </Button>
        <ConversationModal
          canComment={data.canEdit}
          isOpen={isConversationModalOpen}
          onClose={() => setIsConversationModalOpen(false)}
          conversation={data.internalConversation.map((msg) => ({
            id: msg.id,
            value: msg.value,
            createdBy: `${msg.createdBy?.firstName ?? ""} ${msg.createdBy?.lastName ?? ""}`,
            createdAt: moment(msg.createdAt).format("DD MMM YY hh.mm"),
          }))}
          handleMessage={async (msg: string) => {
            const res = await editEvent({ eventId: data.id, editSourcingEventRequest: { addInternalMessage: msg } });
            if ("data" in res) dispatch(setSourcingEvent(res.data));
          }}
          isLoading={isLoadingEditEvent}
        />
      </GridItem>
      <GridItem area="createdMeta" pl="4" pb="5">
        <Text fontSize={"sm"} color="smMuted" mt="5px">
          {t("Created by")} <b>{`${data.createdBy.firstName} ${data.createdBy.lastName}`} </b>
          {t("on")} {displayDate(data.createdAt)}.
        </Text>
        <Intro description={description ?? ""} />
        {data.referenceId && (
          <Flex alignItems="start" flexDirection={"column"} pb="4">
            <Text fontSize={"sm"} fontWeight={"semibold"}>
              {t("Internal reference")}
            </Text>
            <Text fontSize={"sm"}>{data.referenceId}</Text>
          </Flex>
        )}
        {sub.hasProjectAccess && (
          <Flex flexDirection="column" fontWeight="bold" pb="4">
            <Text fontSize="sm">{t("Projects")}</Text>
            <Flex flexWrap="wrap" rowGap={1} columnGap={1}>
              {data.projects?.map((p) => (
                <Button key={p.id} size="xs" onClick={() => navigate(urls.projects.view.go(p.id))}>{`${
                  p.externalId ? `${p.externalId} - ` : ""
                }${p.name}`}</Button>
              ))}
            </Flex>
          </Flex>
        )}
        <Flex alignItems="center" pt="5">
          <Flex>
            <Icon as={FaClock} color="teal" _dark={{ color: "teal.200" }} w="14px" h="14px" />
          </Flex>
          <Flex ml="5px">
            <Text fontSize={"md"} color="teal" _dark={{ color: "teal.200" }}>
              {t("Bid deadline")}: {deadline ? displayDateWithTime(deadline, t("at")) : null}
            </Text>
          </Flex>
          <Spacer />
        </Flex>
      </GridItem>
    </>
  );
};
